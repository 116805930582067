
import { defineComponent } from "vue";
import GameContainer from "@/components/GameContainer.vue";
import Slider from "@/components/Slider.vue";
import apiService from "@/api/api";
import SliderItem, {newItem} from "@/interfaces/slider_item";

export default defineComponent({
  name: "GameInfo",
  components: {Slider,GameContainer},
  data() {
    let game: any = {}
    const sliderItems: SliderItem[] = []
    return {
      game,
      sliderItems,
      testImage: 'https://framerusercontent.com/images/xjO2MpSWQs8XzUiqU5xb9jwxQ.png',
      testTitle: 'Children\'s doctor - Dentist'
    }
  },
  computed: {
    NAME(): string {
      if (!this.game.name) return 'Mobile app name'
      else return this.game.name
    },
    DESCRIPTION(): string {
      if (!this.game.description) return 'Mobile app description'
      else return this.game.description
    }
  },
  beforeMount() {
    // console.log(this.$route)
    this.$store.dispatch('CurrentLangInitialized').then((inited: boolean) => {
      if (inited) {
        apiService.getGameDescription(this.$store.getters.URL, this.$store.getters.CURRENT_LANG_ID, +this.$route.params.id).then(r => {
          // console.log('GAME: ', r)
          if (!r.data.error && r.data.Game) {
            this.game = r.data.Game
            if (this.game.screens) {
              const items: SliderItem[] = []
              for (let i = 0; i < this.game.screens.length; i++) {
                items.push(newItem({image: this.game.screens[i]}))
              }
              this.sliderItems = items
            }
            // console.log('sliderItems: ', this.sliderItems)
            // this.$forceUpdate()
          }
        })
      }
    })
  },
  methods: {
    getSrc(image: string, dir?: string): string {
      return apiService.getImageSrc(image, !dir ? 'games' : dir)
    }
  }
})
